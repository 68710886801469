
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UITextField, UIButton, UIAlertView, UICheckbox } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import { Employee } from '@/core/model/Types';
import AppContext from '@/core/AppContext'
import * as Permission from '@/core/safe/Permissions'

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UITextField, UIAlertView, UICheckbox,
    ToDeleteButton
  },
  data() {
    return {
      grantedEdit: false,
      grantedPerm: false,
      displayLastName: '',
      displayFirstName: '',
      displayFatherName: '',
      displayLogin: '',
      displayPassword: '',
      displayHidden: false,
      displayPerms: new Set<string>(),
      docPermItems: [
        { name: 'Заявки', value: Permission.PURCHASE_ORDER_EDIT },
        { name: 'Закрытие заявки', value: Permission.CLOSING_ORDER_EDIT },
        { name: 'Поступления', value: Permission.DELIVERY_INVENTORY_EDIT },
        { name: 'Перемещения', value: Permission.TRANSFER_INVENTORY_EDIT },
        { name: 'Списания', value: Permission.WRITEOFF_INVENTORY_EDIT },
      ],
      statusPermItems: [
        { name: 'Согласована', value: Permission.CHANGE_ORDER_STATUS_AGREED },
        { name: 'Утверждена', value: Permission.CHANGE_ORDER_STATUS_APPROVED },
        { name: 'Отменена', value: Permission.CHANGE_ORDER_STATUS_CANCELED },
      ],
      unitPermItems: [
        { name: 'Оборудование', value: Permission.EQUIPMENT_EDIT },
        { name: 'Участки', value: Permission.PLACE_EDIT },
        { name: 'Склады', value: Permission.STORAGE_EDIT },
        { name: 'Контрагенты', value: Permission.COUNTERPARTY_EDIT },
        { name: 'Пользователи', value: Permission.EMPLOYEE_EDIT },
        { name: 'Единицы измерения', value: Permission.MEASURE_EDIT },
      ],
      otherPermItems: [
        { name: 'Настройки', value: Permission.SETTINGS },
        { name: 'Операции', value: Permission.OPERATIONS },
        { name: 'Администатор', value: Permission.ADMINISTRATOR }
      ],
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  computed: {
    objId(): string { return this.$sm.getCurrentParam('id') },
  },
  methods: {
    configureView(entry: Employee) {
      this.displayLastName = entry.lastName
      this.displayFirstName = entry.firstName
      this.displayFatherName = entry.fatherName
      this.displayLogin = entry.login
      this.displayPassword = entry.password
      this.displayHidden = entry.hidden;
      this.displayPerms = new Set(entry.permissions || [])
      this.toDelete = Boolean(entry.toDelete)
    },
    updatePermissions(value: string, checked: boolean) {
      if (checked) {
        this.displayPerms.add(value)
      } else {
        this.displayPerms.delete(value)
      }
    },
    updateHidden(value: string, checked: boolean) {
      this.displayHidden = checked;
    },
    handleSaveClick() {

      const errors = []
      if (!this.displayLastName) errors.push('Фамилия')
      if (!this.displayFirstName) errors.push('Имя')
      if (errors.length > 0) {
        this.alert = { title: 'Ошибка', text: ['Заполните реквизиты:', `"${errors.join('", "')}"`] }
        return
      }

      this.$dm.getEntryById(Employee.MODEL, this.objId)
        .then(storedEntry => {
          let entry = storedEntry || Employee.new(this.objId)
          // Обновляем объект
          entry.setValues({
            [Employee.VAL_NAME_LAST]: this.displayLastName,
            [Employee.VAL_NAME_FIRST]: this.displayFirstName,
            [Employee.VAL_NAME_FATHER]: this.displayFatherName,
            [Employee.VAL_LOGIN]: this.displayLogin,
            [Employee.VAL_PASSWORD]: this.displayPassword,
            [Employee.VAL_HIDDEN]: this.displayHidden,
            [Employee.VAL_PERMISSIONS]: Array.from(this.displayPerms),
            [Employee.VAL_TODELETE]: this.toDelete
          })
          // Сохраняем объект
          return this.$dm.putEntry(Employee.MODEL, entry)
            .then(() => this.$sm.goBack())
        })
    },
  },
  created() {

    AppContext.getSafeManager().getCurrentPermissions(perms => {
      this.grantedPerm = perms.includes(Permission.ADMINISTRATOR)
      this.grantedEdit = this.grantedPerm || perms.includes(Permission.EMPLOYEE_EDIT)
    })

    this.isLoading = true
    this.$dm.getEntryById(Employee.MODEL, this.objId)
      .then(storedEntry => {
        let entry = storedEntry || Employee.new(this.objId)
        this.configureView(entry)
      })
      .catch(() => this.$sm.goBack())
      .finally(() => this.isLoading = false)
  }
})
