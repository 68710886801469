import StoredEntry from "../StoredEntry"
import EntryHelper from "../EntryHelper"
import Property, { BoolProperty, StringArrayProperty, StringProperty } from "../Property"

export default class Employee extends StoredEntry {
  static MODEL = 'wt4o'

  static VAL_NAME_LAST = 'to2r'
  static VAL_NAME_FIRST = 'wqc7'
  static VAL_NAME_FATHER = 'u47b'
  static VAL_LOGIN = 'zv7g'
  static VAL_PASSWORD = 'b1mk'
  static VAL_HIDDEN = 'e4ni'
  static VAL_PERMISSIONS = 'bt8u'
  static VAL_TODELETE = 'a3yq'
  
  static PROPS: Property[] = StoredEntry.PROPS.concat([
    new StringProperty(this.VAL_NAME_LAST), new StringProperty(this.VAL_NAME_FIRST),
    new StringProperty(this.VAL_NAME_FATHER), new StringProperty(this.VAL_LOGIN),
    new StringProperty(this.VAL_PASSWORD), new BoolProperty(this.VAL_HIDDEN),
    new StringArrayProperty(this.VAL_PERMISSIONS), new BoolProperty(this.VAL_TODELETE)
  ])

  // ---

  constructor(rawData?: any) {
    super(rawData)
  }

  get name(): string { return this.getShortName() }
  
  get lastName(): string { return this.getValue(Employee.VAL_NAME_LAST) }
  get firstName(): string { return this.getValue(Employee.VAL_NAME_FIRST) }
  get fatherName(): string { return this.getValue(Employee.VAL_NAME_FATHER) }
  get login(): string { return this.getValue(Employee.VAL_LOGIN) }
  get password(): string { return this.getValue(Employee.VAL_PASSWORD) }
  get hidden(): boolean { return this.getValue(Employee.VAL_HIDDEN) }
  get permissions(): string[] { return this.getValue(Employee.VAL_PERMISSIONS) }
  get toDelete(): boolean { return this.getValue(Employee.VAL_TODELETE) }

  getShortName(): string {
    const arr = []
    if (this.lastName) arr.push(`${this.lastName} `)
    if (this.firstName) arr.push(`${this.firstName.charAt(0)}.`)
    if (this.fatherName) arr.push(`${this.fatherName.charAt(0)}.`)
    return arr.join('').trim()
  }

  getFullName(): string {
    const arr = []
    arr.push(this.lastName || '')
    arr.push(this.firstName || '')
    arr.push(this.fatherName || '')
    return arr.join(' ')
  }

  static new(value: any): Employee {
    return EntryHelper.fillEntry(new Employee(), value)
  }
}