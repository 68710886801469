
import { defineComponent } from 'vue';
import DefaultPage from '@/use/components/DefaultPage.vue';
import { UIPanel, UIToolbar, UIButton, UIDateField, UITextField, UIEntryField, UITextArea, UIAlertView } from '@/use/base/Types';
import { UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton } from '@/use/base/Types';
import ToDeleteButton from '@/use/components/ToDeleteButton.vue';
import AppContext from '@/core/AppContext';
import * as Permission from '@/core/safe/Permissions'
import * as SettingsKey from '@/core/settings/SettingsKeys'
import { Place, Employee, Equipment, Measure } from '@/core/model/Types';
import { PurchaseOrder, PurchaseOrderRow, PurchaseOrderStatus, ClosingOrder, ClosingOrderRow } from '@/core/model/Types';
import PurchaseOrderStatusM from '@/lib/util/PurchaseOrderStatusM';
import DataRequest from '@/core/data/DataRequest';
import SessionCache from '@/lib/util/SessionCache';
import { PDFBuilder, PDFTable } from '@/lib/pdf/PDFTypes';
import PurchaseOrderHelper from './PurchaseOrderHelper';
import EntryLoader from '@/lib/data/EntryLoader';
import EntryMap from '@/lib/data/EntryMap';
import { DateUtils, EntryUtils, NumberUtils, StringUtils, TableUtils } from '@/lib/util/Utils';

const STATUS_PERMISSION_MAP = {
  [PurchaseOrderStatus.AGREED]: Permission.CHANGE_ORDER_STATUS_AGREED,
  [PurchaseOrderStatus.APPROVED]: Permission.CHANGE_ORDER_STATUS_APPROVED,
  [PurchaseOrderStatus.CANCELED]: Permission.CHANGE_ORDER_STATUS_CANCELED,
}

export default defineComponent({
  components: {
    DefaultPage, 
    UIPanel, UIToolbar, UIButton, UIDateField, UITextField, UIEntryField, UITextArea, UIAlertView,
    UITable, UITableRow, UITableCell, UITextCell, UIEntryCell, UIImageButton,
    ToDeleteButton
  },
  data() {
    return {
      loader: new EntryLoader(),
      docId: '',
      currentUser: undefined as Employee | undefined,
      permissions: new Array<string>(),
      date: new Date(),
      number: '',
      place: undefined,
      declarer: undefined,
      executor: undefined,
      executorDate: undefined as Date | undefined,
      status: undefined,
      comment: '',
      tableWidths: TableUtils.headers(m => [m.INDEX, m.flex(2), m.flex(1), m.flex(1), m.MEASURE, m.AMOUNT, m.flex(1), m.ACTION]),
      tableHeaders: ['№', 'Наименование', 'Тех. характеристика', 'Оборудование', 'Ед.', 'Кол-во', 'Примечание', 'X'],
      tableRows: new Array<any>(),
      loadStatus: 'unknown',
      toDelete: false,
      isLoading: false,
      alert: undefined as any,
    }
  },
  watch: {
    tableRows: {
      deep: true,
      handler(newValue) {
        if (!TableUtils.isContainsEmptyRow(newValue)) {
          this.tableRows.push({ _key: StringUtils.getUUID() })
        }
      },
    }
  },
  computed: {
    canEdit(): boolean { 
      if (!this.permissions.includes(Permission.PURCHASE_ORDER_EDIT)) {
        return false
      }
      const statusPermis = STATUS_PERMISSION_MAP[this.loadStatus]
      if (!statusPermis) {
        return true
      }
      return this.permissions.includes(statusPermis)
    },
    canClosing(): boolean {
      return this.permissions.includes(Permission.CLOSING_ORDER_EDIT)
    },
  },
  methods: {
    table_handleRemoveRow(rowIndex: number) {
      this.tableRows.splice(rowIndex, 1)
    },
    configureView(doc: PurchaseOrder | undefined, rows: PurchaseOrderRow[]) {
      this.date = (doc) ? doc.date : new Date()
      this.number = (doc) ? doc.number : AppContext.getSettingsManager().getNextNumber(PurchaseOrder.MODEL)
      this.place = (doc) ? this.loader.data.getDisplayItem(Place.MODEL, doc.placeId) : undefined
      this.declarer = this.loader.data.getDisplayItem(Employee.MODEL, (doc) ? doc.declarerId : this.currentUser!.id)
      this.executor = (doc) ? this.loader.data.getDisplayItem(Employee.MODEL, doc.executorId) : undefined
      this.executorDate = (doc) ? doc.executorDate : undefined
      this.status = PurchaseOrderStatusM.getDisplayItem((doc) ? doc.status : PurchaseOrderStatus.NEW)
      this.comment = (doc) ? doc.comment : ''
      this.tableRows = rows.sort((a, b) => a.index - b.index).map(row => {
        return {
          _key: StringUtils.getUUID(),
          id: row.id,
          inventoryName: row.inventoryName,
          inventoryDetails: row.inventoryDetails,
          equipment: this.loader.data.getDisplayItem(Equipment.MODEL, row.equipmentId),
          measure: row.measure,
          amount: `${row.amount || 0}`,
          comment: row.comment
        }
      })
      this.loadStatus = (doc) ? doc.status : PurchaseOrderStatus.NEW
      this.toDelete = (doc) ? Boolean(doc.toDelete) : false
    },
    reloadView() {

      this.isLoading = true

      const request = new DataRequest()
      request.addGetAllEntries(Place.MODEL, Place.MODEL)
      request.addGetEntriesWhere(Employee.MODEL, [
        [ Employee.VAL_HIDDEN, '==', 0 ]
      ], Employee.MODEL)
      request.addGetAllEntries(Equipment.MODEL, Equipment.MODEL)
      request.addGetAllEntries(Measure.MODEL, Measure.MODEL)

      this.loader.load(request)
        .then(() => {

          if (this.docId === 'new') {
            this.configureView(undefined, [])
          }
          else {

            const request = new DataRequest()
            request.addGetEntriesByIds(PurchaseOrder.MODEL, [this.docId], 'doc')
            request.addGetEntriesWhere(PurchaseOrderRow.MODEL, [
              [ PurchaseOrderRow.VAL_DOC_ID, '==', this.docId ]
            ], 'rows')

            return this.$dm.send(request)
              .then(results => {

                const doc = results.getValue('doc').find(() => true)
                if (!doc) throw new Error(`Документ (${this.docId}) не существует!`)

                const rows = results.getValue('rows') || []
                this.configureView(doc, rows)
              })
          }
        })
        .catch(msg => {
          console.error(msg)
          this.$sm.goBack()
        })
        .finally(() => this.isLoading = false)
    },
    tableVariantsGetter(key: string, rowIndex: number, callback: any) { return this.getVariantsGetter(key, rowIndex, callback) },
    placeVariantsGetter(callback: any) { return this.getVariantsGetter('place', 0, callback) },
    declarerVariantsGetter(callback: any) { return this.getVariantsGetter('declarer', 0, callback) },
    executorVariantsGetter(callback: any) { return this.getVariantsGetter('executor', 0, callback) },
    statusVariantsGetter(callback: any) { return this.getVariantsGetter('status', 0, callback) },
    getVariantsGetter(key: string, rowIndex: number, callback: any) {
      return this.getVariantsPromise(key, rowIndex)
        .then(value => callback(value))
        .catch(() => callback(undefined))
    },
    getPromise(value: any): Promise<any> {
      return new Promise((resolve) => { resolve(value) })
    },
    getVariantsPromise(key: string, rowIndex: number): Promise<any> {
      rowIndex // Типо использовали

      if (key === 'place') {
         return this.getPromise(this.loader.data.getDisplayItems(Place.MODEL))
      }
      else if (key === 'declarer') {
         return this.getPromise(this.loader.data.getDisplayItems(Employee.MODEL))
      }
      else if (key === 'executor') {
         return this.getPromise(this.loader.data.getDisplayItems(Employee.MODEL))
      }
      else if (key === 'status') {
         return this.getPromise(PurchaseOrderStatusM.getDisplayItems())
      }
      else if (key === 'measure') {
         return this.getPromise(this.loader.data.getEntries(Measure.MODEL).map( m => m.name ))
      }
      else if (key === 'equipment') {

        if (!EntryUtils.isEmpty(this.place)) {
          return this.getPromise(
            this.loader.data.getDisplayItems(Equipment.MODEL, it => it.placeId === EntryUtils.getId(this.place))
          )
        } else {
          return this.getPromise(this.loader.data.getDisplayItems(Equipment.MODEL))
        }
      } 
      else {
        return this.getPromise(undefined)
      }
    },
    prepareSaveData(): any {

      let docId = (this.docId === 'new') ? this.$dm.newEntryId() : this.docId
      
      const doc = PurchaseOrder.new({
        [PurchaseOrder.VAL_ID]: docId,
        [PurchaseOrder.VAL_DATE]: this.date,
        [PurchaseOrder.VAL_NUMBER]: this.number,
        [PurchaseOrder.VAL_PLACE_ID]: EntryUtils.getId(this.place),
        [PurchaseOrder.VAL_DECLARER_ID]: EntryUtils.getId(this.declarer),
        [PurchaseOrder.VAL_EXECUTOR_ID]: EntryUtils.getId(this.executor),
        [PurchaseOrder.VAL_EXECUTOR_DATE]: this.executorDate,
        [PurchaseOrder.VAL_STATUS]: EntryUtils.getId(this.status),
        [PurchaseOrder.VAL_COMMENT]: this.comment,
        [PurchaseOrder.VAL_TODELETE]: this.toDelete
      })
      
      const rows = TableUtils.getFilledRows(this.tableRows).map(row => {
        return PurchaseOrderRow.new({
          [PurchaseOrderRow.VAL_ID]: row.id,
          [PurchaseOrderRow.VAL_INVENTORY_NAME]: row.inventoryName,
          [PurchaseOrderRow.VAL_INVENTORY_DETAILS]: row.inventoryDetails,
          [PurchaseOrderRow.VAL_EQUIPMENT_ID]: EntryUtils.getId(row.equipment),
          [PurchaseOrderRow.VAL_MEASURE]: row.measure,
          [PurchaseOrderRow.VAL_AMOUNT]: Number(row.amount),
          [PurchaseOrderRow.VAL_COMMENT]: row.comment
        })
      })

      return { doc: doc, rows: rows }
    },
    checkOnSavePermission() {
      const permis = STATUS_PERMISSION_MAP[EntryUtils.getId(this.status) || '']
      if (permis && !this.permissions.includes(permis)) {
        this.alert = { title: 'Ограничение прав доступа', text: 'У вас нет прав на установку статуса: "' + EntryUtils.getName(this.status) + '"!' }
        return false
      } else {
        return true
      }
    },
    handleOkClick() {
      if (!this.checkOnSavePermission()) return
      const d = this.prepareSaveData()
      PurchaseOrderHelper.save(d.doc, d.rows, this.loader.data as EntryMap)
        .then(() => {
          AppContext.getSettingsManager().registryLastNumber(PurchaseOrder.MODEL, this.number)
          this.$sm.goBack()
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handleSaveClick() {
      if (!this.checkOnSavePermission()) return
      const d = this.prepareSaveData()
      PurchaseOrderHelper.save(d.doc, d.rows, this.loader.data as EntryMap)
        .then(() => {
          if (this.docId === 'new') {
            AppContext.getSettingsManager().registryLastNumber(PurchaseOrder.MODEL, this.number)
            // Изменим url
            this.docId = d.doc.id
            this.$sm.goToObjectEditor(PurchaseOrder.MODEL, d.doc.id)
          }
          this.reloadView()
          this.alert = { text: 'Сохранено!' }
        })
        .catch(msg => this.alert = { title: 'Ошибка', text: msg })
    },
    handlePrintClick() {

      const sm = AppContext.getSettingsManager()

      const printTableHeaders = TableUtils.headers(m => [
        { name: '№', width: m.INDEX, alignment: 'right' },
        { name: 'Наименование', width: m.flex(2) },
        { name: 'Тех. характеристика', width: m.flex(1) },
        { name: 'Оборудование', type: 'entry', width: m.flex(1) },
        { name: 'Ед.', width: m.MEASURE },
        { name: 'Кол-во', width: m.AMOUNT, alignment: 'right' },
        { name: 'Примечание', width: m.flex(1) },
      ])

      const table = new PDFTable()
      table.setColumnWidths(printTableHeaders.map(it => it.width))
      table.setColumnAligment(printTableHeaders.map(it => it.alignment || 'left'))
      table.setHeaders(printTableHeaders.map(it => it.name))

      TableUtils.getFilledRows(this.tableRows).forEach((row, i) => {
        table.addRow([
          `${i + 1}`,
          row.inventoryName,
          row.inventoryDetails,
          EntryUtils.getName(row.equipment),
          row.measure,
          NumberUtils.formatedNumber(row.amount),
          row.comment
        ])
      })

      const builder = new PDFBuilder('landscape')
      builder.addOppositeColumns(
        [
          'Согласовано:', 'Главный инженер',
          `${sm.getParam(SettingsKey.CHIEF_ENGINEER_SHORT_NAME)} ___________________`
        ],
        [
          'Утверждаю:', 'Директор ' + sm.getParam(SettingsKey.COMPANY_FULL_NAME),
          `${sm.getParam(SettingsKey.DIRECTOR_SHORT_NAME)} ___________________`
        ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTitle(
        [
          'ЗАЯВКА', 'НА ЗАКУПКУ МАТЕРИАЛОВ ДЛЯ ТЕКУЩЕЙ ЗАМЕНЫ',
          `№ ${this.number} от ${DateUtils.formatedDate(this.date)}`
        ],
        { margin: [ 0, 0, 0, 20 ] }
      )
      builder.addTable(table, { margin: [ 0, 0, 0, 40 ] })
      builder.addText(`Заявитель ___________________ ${EntryUtils.getName(this.declarer)}`)
      builder.build()
    },
    handleClosingClick() {

      const rows = TableUtils.getFilledRows(this.tableRows).filter(it => (it.id)).map((row, index) => ClosingOrderRow.new({
          [ClosingOrderRow.VAL_INDEX]: index,
          [ClosingOrderRow.VAL_ORDER_ID]: this.docId,
          [ClosingOrderRow.VAL_ORDER_ROW_ID]: row.id,
          [ClosingOrderRow.VAL_AMOUNT]: row.amount,
        })
      )
      SessionCache.put('i2ze', rows)
      
      this.$sm.goToObjectEditor(ClosingOrder.MODEL, 'new')
    },
    handleRecordsClick() {
      this.$sm.goToDocRecords(PurchaseOrder.MODEL, this.docId)
    },
    handleHistoryClick() {
      this.$sm.goToDocHistory(PurchaseOrder.MODEL, this.docId)
    },
  },
  created() {

    const sm = AppContext.getSafeManager()
    sm.getCurrentEmployee(em => {
      this.currentUser = em
    })
    sm.getCurrentPermissions(perms => {
      this.permissions = perms
    })

    this.docId = this.$sm.getCurrentParam('id')
    this.reloadView()
  }
})
